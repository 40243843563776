var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"align-items-center min-vh-100"},[_c('div',{staticClass:"container mt-3",staticStyle:{"background-color":"white","border-radius":"5px"}},[_c('VisualizzaDocumento'),_c('div',{staticClass:"text-center pb-2"},[_c('h1',[_vm._v(_vm._s(_vm.intermediario.RagioneSociale))]),_c('h4',[_vm._v("("+_vm._s(_vm.intermediario.Descrizione)+")")])]),_c('div',{staticClass:"pt-4"},[(_vm.docs.length == 0)?_c('div',{staticClass:"text-center h4",staticStyle:{"color":"#ee7a13"}},[_vm._v(" - Non sono ancora presenti "+_vm._s(_vm.subTitle)+" - ")]):_c('div',{staticClass:"text-center",attrs:{"id":"titolo_tabella"}},[_c('h4',[_vm._v("Elenco "+_vm._s(_vm.subTitle))])]),_c('CDataTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.docs.length > 0),expression:"docs.length > 0"}],ref:"tabella_doc",attrs:{"id":"UO_table","items":_vm.docs,"fields":_vm.fields_Dettaglio,"sorter":"","hover":"","border":"","itemsPerPage":20,"pagination":"","table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"Download",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('a',{attrs:{"href":item.Id},on:{"click":function($event){$event.preventDefault();_vm.preview(item.Id, _vm.richiesta);
                _vm.titoloModale(
                  _vm.richiesta == 'Aud' ? 'AUDIT' : 'RILIEVO',
                  item.Numero_Formatted
                );}}},[_c('i',{staticClass:"fas fa-download fa-2x"})])])]}}])})],1),_c('div',[(_vm.proponente)?_c('CButton',{staticClass:"ml-2",attrs:{"color":"primary","variant":"outline","to":{
          name: 'DettagliProponente',
          params: {
            intermediario: _vm.proponente,
            proponenti: _vm.proponenti,
          },
        }}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" Indietro")]):_c('CButton',{staticClass:"ml-2",attrs:{"color":"primary","variant":"outline","to":{ name: 'Documentale', params: { origine: _vm.origine } }}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" Indietro")])],1),_c('br')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }