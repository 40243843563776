var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"align-items-center min-vh-100"},[_c('div',{staticClass:"container mt-3",staticStyle:{"background-color":"white","border-radius":"5px"}},[_c('div',{staticClass:"text-center pb-2"},[_c('h1',[_vm._v(_vm._s(_vm.intermediario.RagioneSociale))]),_c('h4',[_vm._v("("+_vm._s(_vm.intermediario.Descrizione)+")")])]),_c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"text-center",attrs:{"id":"titolo_tabella"}},[_c('h4',[_vm._v("Elenco prodotti attivi")])]),_c('CDataTable',{ref:"tabella_prod",attrs:{"id":"prodotti_table","items":_vm.prodotti,"fields":_vm.fields_PRODOTTI,"sorter":"","hover":"","border":"","pagination":"","table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","itemsPerPage":20,"items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"Intermediario_Codice",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.Intermediario_Codice))])]}},{key:"Codice_Ramo",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.Codice_Ramo))])]}}])})],1),_c('div',[_c('CButton',{staticClass:"ml-2",attrs:{"color":"primary","variant":"outline","to":{ name: 'Documentale', params: { origine: 'Emittenti' } }}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" Indietro")])],1),_c('br')])])}
var staticRenderFns = []

export { render, staticRenderFns }